<template>
  <div>
    <!-- <b-row>
      <b-col sm="10" md="7" lg="7" class="pr-lg-3">
        <div>
          <h6 class="">Name of the Client</h6>
          <b-form-input id="clientName" v-model="clientName" placeholder="" />
        </div>
      </b-col>
      <b-col sm="10" md="5" lg="5" class="pt-2">
        <div class="d-flex justify-content-center">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning"
            class="mr-2"
          >
            Save and Draft
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class=""
          >
            Save and Publish
          </b-button>
        </div>
      </b-col>
    </b-row> -->
    <b-row class="d-lg-flex justify-content-between">
      <b-col sm="10" md="7" lg="7" class="">
        <div class="card p-lg-3 p-2 pb-lg-4 pb-2">
          <div>
            <h6 class="">Name of the Client</h6>
            <b-form-input id="clientName" v-model="clientName" placeholder="" />
          </div>
          <div class="mt-1">
            <h6 class="">About the Client</h6>
            <b-form-textarea
              id="clientDetail"
              v-model="clientDetail"
              placeholder="Write Here..."
              rows="8"
            />
          </div>
          <div class="mt-2">
            <h6 class="mr-2">Company Employee Strength</h6>
            <div class="d-flex">
              <b-form-radio-group
                id="employeeStrength"
                v-model="selectEmpStrength"
                :options="empStrengthOptions"
                class="mt-1 font-weight-bold"
                name="employeeStrength"
              />
            </div>
          </div>
          <div>
            <h4 class="mt-3 mb-2 bold">Location Details</h4>
          </div>
          <div class="">
            <h6 class="">Address line 1</h6>
            <b-form-input id="address1" v-model="address1" placeholder="" />
          </div>
          <div class="mt-1">
            <h6 class="">Address line 2</h6>
            <b-form-input id="address2" v-model="address2" placeholder="" />
          </div>
          <div class="d-lg-flex d-md-flex">
            <div class="w-lg-50 w-md-50 w-100 mr-2">
              <h6 class="mt-1">City</h6>
              <b-form-input id="city" v-model="city" placeholder="" />
            </div>
            <div class="w-lg-50 w-md-50 w-100">
              <h6 class="mt-1">State</h6>
              <b-form-input id="state" v-model="state" placeholder="" />
            </div>
          </div>
          <div class="d-lg-flex d-md-flex">
            <div class="w-lg-50 w-md-50 w-100 mr-2">
              <h6 class="mt-1">ZIPCODE</h6>
              <b-form-input id="zipCode" v-model="zipCode" placeholder="" />
            </div>
            <div class="w-lg-50 w-md-50 w-100">
              <h6 class="mt-1">Country</h6>
              <!-- <v-select id="country" v-model="country" :options="countries" /> -->
              <v-select
                id="country"
                v-model="country"
                label="title"
                :options="countries"
              />
            </div>
          </div>
          <div class="mt-3 ml-0 mr-0 mb-0">
            <h4 class="my-2 bold">Payment Structure</h4>

            <b-col class="border rounded-lg px-2 py-1">
              <div>
                <h6 class="mt-1">Hourly Pricing (Phone)</h6>
                <b-input-group prepend="$" class="input-group-merge">
                  <b-form-input
                    id="hourlyPricingPhone"
                    v-model="hourlyPricingPhone"
                    placeholder=""
                  />
                </b-input-group>
              </div>
              <div>
                <h6 class="mt-1">Hourly Pricing (Face to Face)</h6>
                <b-input-group prepend="$" class="input-group-merge">
                  <b-form-input
                    id="hourlyPricingFace"
                    prepend="$"
                    v-model="hourlyPricingFace"
                    placeholder=""
                  />
                </b-input-group>
              </div>
            </b-col>
          </div>
        </div>
      </b-col>
      <b-col sm="10" md="5" lg="5" class="">
        <!--save draft publish btn - for medium and large device  -->
        <div class="card p-lg-3 p-2 d-none d-lg-block d-md-block">
          <div class="d-flex justify-content-center">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-warning"
              class="mr-2"
            >
              Save and Draft
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class=""
            >
              Save and Publish
            </b-button>
          </div>
        </div>

        <div class="card px-lg-3 px-2 pb-lg-5 pb-md-5 pb-2">
          <b-col class="rounded-lg my-3">
            <h4 class="bold">Contact Details</h4>
            <div class="mt-2">
              <h6>Email ID</h6>
              <b-form-input id="emailID" v-model="emailID" placeholder="" />
            </div>
            <b-row class="mt-1">
              <b-col lg="5" md="6" sm="12">
                <h6 class="mt-1">Country code</h6>
                <b-form-select
                  v-model="selectCountryCode"
                  :options="countryCodeOptions"
                />
              </b-col>
              <b-col lg="7" md="6" sm="12">
                <h6 class="mt-1">Phone Number</h6>
                <b-form-input
                  id="phoneNumber"
                  v-model="phoneNumber"
                  placeholder=""
                />
              </b-col>
            </b-row>
            <div class="mt-2">
              <h6 class="mt-1">Website</h6>
              <b-form-input id="website" v-model="website" placeholder="" />
            </div>
            <div class="mt-2">
              <h6 class="mt-1">Client Contacts</h6>
              <div class="d-flex">
                <div
                  v-for="(originator, i) in originators.slice(0, 4)"
                  :key="i"
                  class=""
                >
                  <b-avatar :src="originator.img" class="img" />
                </div>
                <!-- show all  -->
                <b-avatar text="AL" variant="light-secondary" class="" />
                <!-- add another  -->
                <b-avatar
                  v-b-modal.modal-add-client
                  :src="require('@/assets/images/icons/plus-circle@2x.png')"
                  variant="light-secondary"
                  class="ml-1"
                />
              </div>
            </div>
            <div class="mt-3 mb-2">
              <h4 class="bold">Social Presence</h4>
            </div>
            <div>
              <h6 class="mt-1">Linkedin</h6>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon size="1.5x" icon="LinkedinIcon" />
                </b-input-group-prepend>
                <b-form-input v-model="linkedin" placeholder="Search" />
              </b-input-group>
            </div>
            <div>
              <h6 class="mt-1">Facebook</h6>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon size="1.5x" icon="FacebookIcon" />
                </b-input-group-prepend>
                <b-form-input v-model="facebook" placeholder="Search" />
              </b-input-group>
            </div>
            <div>
              <h6 class="mt-1">Instagram</h6>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon size="1.5x" icon="InstagramIcon" />
                </b-input-group-prepend>
                <b-form-input v-model="instagram" placeholder="Search" />
              </b-input-group>
            </div>
            <div>
              <h6 class="mt-1">Skype</h6>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <b-img
                    href="#foobar"
                    :src="require('@/assets/images/icons/skype.png')"
                    class=""
                  />
                </b-input-group-prepend>
                <b-form-input v-model="skype" placeholder="Search" />
              </b-input-group>
            </div>
            <div class="mt-4">
              <div>
                <h6>Attach Files</h6>
                <!-- <div v-for="(file, index) in " :key="file.name"> -->

                <div
                  v-for="(file, index) in attachFiles"
                  :key="file.name"
                  class="d-flex justify-content-between align-items-center p-1 mb-1 file_bg"
                >
                  <h6 class="my-auto">{{ file.name }}</h6>
                  <b-img
                    @click="removeFile(index)"
                    class="text-white"
                    src="./../../assets/images/icons/crossIcon.png"
                  />
                </div>
              </div>
              <b-form-file
                v-model="attachFiles"
                multiple
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @change="handleAttachFiles"
              />
            </div>
          </b-col>
        </div>
      </b-col>
    </b-row>
    <b-row class="">
      <b-col cols="12" class="">
        <div class="card px-lg-3 px-2 pt-2 py-2">
          <h4 class="mt-3">Other Details</h4>
          <b-form
            ref="form"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          ></b-form>
          <b-row
            v-for="(item, index) in items"
            :id="item.id"
            :key="item.id"
            ref="row"
          >
            <b-col cols="12" class="my-1">
              <div class="d-flex justify-content-between">
                <h6>Notes about the Client</h6>
                <div>
                  <b-img
                    @click="removeItem(index)"
                    class="ml-2"
                    src="./../../assets/images/icons/Icon.png"
                  />
                </div>
              </div>

              <b-form-textarea
                id="textarea-rows"
                placeholder="Write Here..."
                rows="3"
                cols="12"
              />
            </b-col>
          </b-row>

          <div @click="repeateAgain" class="my-2 d-flex align-items-center">
            <b-avatar
              :src="require('@/assets/images/icons/plus-circle@2x.png')"
              variant="light-secondary"
              class="mr-1"
            />
            <h5 class="my-0">Add Another Question</h5>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- button for small device only  -->
    <b-col cols="12" class="d-lg-none d-md-none d-block card p-2 mx-auto">
      <div class="d-flex justify-content-center">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-warning"
          class="mr-2"
        >
          Save and Draft
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class=""
        >
          Save and Publish
        </b-button>
      </div>
    </b-col>
    <!-- modal  -->
    <b-modal
      id="modal-add-client"
      centered
      title="Other Details"
      cancel-title="Close"
      ok-only
      ok-title="Save Details"
      @ok=""
    >
      <!-- <h4 class="bold py-1">Other Details</h4> -->
      <div class="mx-lg-5 mx-md-3 mx-1 mb-3 mt-1">
        <div>
          <h5 class="mt-1">Client Contact Name</h5>
          <b-form-input id="industry" placeholder="" />
        </div>
        <div>
          <h5 class="mt-1">Client Contact Designation</h5>
          <b-form-input id="industry" placeholder="" />
        </div>
        <div>
          <h5 class="mt-1">Client Contact Email</h5>
          <b-form-input id="industry" placeholder="" />
        </div>
        <div class="mt-1">
          <h5 class="">Client Contact Phone Number</h5>
          <b-form-input id="industry" placeholder="" />
        </div>
        <div class="mt-1">
          <h5 class="">Location ( Country )</h5>
          <b-form-input id="industry" placeholder="" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { email, required } from '@validations';
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormFile,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BFormSelect,
  BFormTextarea,
  BImg,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';

export default {
  components: {
    BRow,
    BCard,
    BCol,
    BForm,
    BFormCheckbox,
    BFormInput,
    BFormTextarea,
    BFormRadio,
    BFormDatepicker,
    BButton,
    BAvatar,
    BFormFile,
    BFormRadioGroup,
    BInputGroup,
    BInputGroupPrepend,
    BImg,
    BFormSelect,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // add question
      items: [
        {
          id: 1,
        },
      ],
      nextTodoId: 2,
      //  form

      required,
      email,
      clientName: '',
      clientDetail: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      hourlyPricingFace: '',
      hourlyPricingPhone: '',
      emailID: '',
      phoneNumber: '',
      website: '',
      linkedin: '',
      facebook: '',
      instagram: '',
      skype: '',
      attachFiles: [],
      selectCountryCode: '',
      selectEmpStrength: '',
      clientNotes: '',
      countries: [
        { title: 'India' },
        { title: 'America' },
        { title: 'Bangladesh' },
      ],
      empStrengthOptions: [
        { text: '1-10', value: '1-10' },
        { text: '11-50 ', value: '11-50 ' },
        { text: '51-100', value: '51-100' },
        { text: '101-500 ', value: '101-500 ' },
        { text: '500 Above', value: '500 Above' },
      ],
      countryCodeOptions: [
        { value: '+61', text: 'Australia(+61)' },
        { value: '+689', text: 'French(+689)' },
      ],
      industryOptions: [{ title: 'Option 1' }, { title: 'Option 2' }],
      originators: [
        { img: `${require('@/assets/images/portrait/small/avatar-s-1.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-2.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-3.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-4.jpg')}` },
        { img: `${require('@/assets/images/portrait/small/avatar-s-5.jpg')}` },
      ],
    };
  },

  methods: {
    // attach file
    handleAttachFiles(e) {
      this.attachFiles = e.target.files;
    },
    removeFile(index) {
      this.attachFiles.splice(index, 1);
    },

    // form repeater
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}

.demo-inline-spacing {
  margin-top: -15px;
}
#present_job {
  overflow: overlay;
}
.title {
  margin-left: 5px;
}
.img {
  margin-right: 10px;
  width: 35px;
  height: 35px;
}
.file_bg {
  background-color: #dcdcdc;
}
</style>
